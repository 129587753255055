'use client';

import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { SiTelegram } from 'react-icons/si';
import usePublicRuntimeConfig from 'modules/common/hooks/usePublicRuntimeConfig';
import Image from 'next/image';

export default function ExchangeDetailModal({ open, onClose }: any) {
  const { t } = useTranslation();
  const { whatsapp } = usePublicRuntimeConfig();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-20 inset-0 overflow-y-auto" onClose={() => {}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div className="mx-auto w-full">
                <Image className="h-12 w-auto" src="/logo/main.png" alt="Headlinker" width="60" height="60" />
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">{t('auth.common:subscribe.title')}</h2>
                <p className="mt-2 text-sm text-gray-600">{t('auth.common:subscribe.subtitle1')}</p>
                <p className="mt-2 text-sm font-bold text-gray-600">{t('auth.common:subscribe.subtitle2')}</p>
              </div>
              <div className="mt-5">
                <a
                  type="button"
                  href={whatsapp}
                  target="_blank"
                  className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={onClose}
                  rel="noreferrer"
                >
                  <SiTelegram className="mr-2" />
                  {t('auth.common:subscribe.whatsapp.cta')}
                </a>
              </div>
              {/* <div className="mt-6 relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">{t('auth.common:subscribe.telegram.alternative')}</span>
                </div>
              </div>
              <div className="mt-5">
                <button
                  type="button"
                  className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={onAuth}
                >
                  <MdEmail className="mr-2" />
                  {t('auth.common:subscribe.login')}
                </button>
              </div> */}
              <div className="mt-2 text-xs font-italic text-gray-500 text-underline text-center block">
                <button onClick={onClose}>{t('auth.common:modal.close')}</button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
