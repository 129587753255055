import React from 'react';
import type { AppProps } from 'next/app';
import { ThemeProvider } from 'next-themes';

import AOS from 'aos';

function DesignProvider({ children }: AppProps & any) {
  React.useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    });
  });
  return <ThemeProvider attribute="class">{children}</ThemeProvider>;
}

export default DesignProvider;
