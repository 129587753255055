
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { AppProps } from 'next/app';
// Design
import 'modules/design/globals.css';
import 'aos/dist/aos.css';
import { DesignProvider } from 'modules/design';
// NProgress
import 'nprogress/nprogress.css'; //styles of nprogress//Binding events.
import 'modules/NProgress/nprogress.theme.css';
import 'modules/NProgress'; //nprogress module
// Notification
import { NotifierContainer } from 'modules/Notification';
// Monitoring
import 'modules/Monitoring/client';
// TRPC
import trpc from 'modules/trpc';
// Auth
import { AuthProvider } from 'modules/auth';
// I18n
import { useLocale } from 'modules/I18n';
const AppWithHooks = ({ Component, pageProps }: AppProps) => {
    useLocale();
    return <Component {...pageProps}/>;
};
function MyApp(props: AppProps) {
    const { pageProps } = props;
    return (<DesignProvider pageProps={pageProps}>
      <AuthProvider pageProps={pageProps}>
        <NotifierContainer />
        <AppWithHooks {...props}/>
      </AuthProvider>
    </DesignProvider>);
}
const __Next_Translate__Page__18a0c4e98b0__ = trpc.withTRPC(MyApp);

    export default __appWithI18n(__Next_Translate__Page__18a0c4e98b0__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  