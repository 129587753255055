const config = require('./config/i18n');

module.exports = {
  ...config,
  locales: ['en', 'fr'],
  defaultLocale: 'en',
  pages: {
    '*': ['common.header', 'common.footer', 'common.seo', 'auth.common', 'auth.custom'],
    '/': ['project.home'],
    '/user/me': ['auth.form'],
    'rgx:^/__admin/.*$': ['common.table', 'auth.admin', 'form.common'],
  },
  debug: process.env.NODE_ENV === 'development',
};
