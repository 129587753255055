import Router from 'next/router';
export const NEXT_PUBLIC_GA_ID = process.env.NEXT_PUBLIC_GA_ID;
const isDev = !NEXT_PUBLIC_GA_ID || process.env.NODE_ENV === 'development';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL) => {
  console.log(`📈 page view ${url}`);
  if (isDev) {
    return;
  }
  // @ts-ignore
  gtag('config', NEXT_PUBLIC_GA_ID, {
    page_path: url,
  });
};

type GTagEvent = {
  action: string;
  category: string;
  label?: string;
  value?: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent) => {
  console.log('📈', action, category, label, `isDev:${isDev}`);
  if (isDev) {
    return;
  }
  // @ts-ignore
  gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

export const trackAuthOpenModal = () => {
  event({
    action: `auth_open`,
    category: 'auth',
    label: window?.location?.pathname,
  });
};

export const trackAuthCloseModal = () => {
  event({
    action: `auth_close`,
    category: 'auth',
    label: window?.location?.pathname,
  });
};

export const trackAuthLogin = (type: string) => {
  event({
    action: `auth_login_${type}`,
    category: 'auth',
    label: window?.location?.pathname,
  });
};

export const trackAuthLoginError = (type: string) => {
  event({
    action: `auth_login_error_${type}`,
    category: 'auth',
    label: window?.location?.pathname,
  });
};

Router.events.on('routeChangeComplete', pageview);
