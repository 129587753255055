module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  keySeparator: false, // to be able to use `.` in the key names
  logBuild: false,
  loadLocaleFrom: async (lang, ns) => {
    if (ns.includes('.')) {
      const [module, namespace] = ns.split('.');
      return import(`../../../modules/${module}/locales/${lang}/${namespace}.yaml`).then((m) => m.default);
    }

    // You can use a dynamic import, fetch, whatever. You should
    // return a Promise with the JSON file.
    return import(`../../../locales/${lang}/${ns}.yaml`).then((m) => m.default);
  },
  pages: {},
};
