'use client';
/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { FaGoogle, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { signIn } from 'modules/auth';
import useNotifier from 'modules/Notification/hooks/useNotifier';
import { useTranslation } from 'modules/I18n';
import { trackAuthLogin, trackAuthLoginError } from 'modules/Analytics';

export default function LoginForm({ onSuccess }: any) {
  const { t, locales } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const emailRef = React.useRef<HTMLInputElement>(null);
  const { notify } = useNotifier();

  const onEmailSignin = async () => {
    const email = emailRef.current?.value;
    trackAuthLogin('email');
    try {
      setLoading(true);
      const result = await signIn<'email'>('email', { email, redirect: false });

      if (!result) {
        return notify('error', t('auth.common:login.signin.error'));
      }
      const { error } = result;

      if (error) {
        trackAuthLoginError('email');
        notify('error', error.toString());
      } else {
        notify('success', t('auth.common:login.signin.email'));
        onSuccess();
      }
    } catch (error: any) {
      trackAuthLoginError('email');
      notify('error', error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  };

  const onSignin = (oauth: 'google' | 'linkedin' | 'twitter') => async () => {
    trackAuthLogin(oauth);
    try {
      setLoading(true);

      await signIn(
        oauth,
        ['/', ...locales?.map((locale) => `/${locale}`)].includes(window.location.pathname)
          ? {
              callbackUrl: `${window.location.origin}/fr/events`,
            }
          : {}
      );
    } catch (error: any) {
      trackAuthLoginError(oauth);
      notify('error', error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  };
  const emailEnabled = process.env.NEXT_PUBLIC_AUTH_EMAIL_ENABLE === 'true';
  const googleEnabled = process.env.NEXT_PUBLIC_AUTH_GOOGLE_ENABLE === 'true';
  const twitterEnabled = process.env.NEXT_PUBLIC_AUTH_TWITTER_ENABLE === 'true';
  const linkedInEnabled = process.env.NEXT_PUBLIC_AUTH_LINKEDIN_ENABLE === 'true';
  const socialEnabled = googleEnabled || twitterEnabled || linkedInEnabled;
  const onlyEmailEnabled = emailEnabled && !socialEnabled;

  return (
    <>
      <div>
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">{t('auth.custom:login.title')}</h2>
        {/* <p className="mt-2 text-sm text-gray-600">
          Or{' '}
          <a href="#" className="font-medium text-primary hover:text-indigo-500">
            start your 14-day free trial
          </a>
        </p> */}
        <p className="mt-2 text-sm text-gray-600">{t('auth.custom:login.subtitle1')}</p>
        <p className="mt-2 text-sm font-bold text-gray-600">{t('auth.custom:login.subtitle2')}</p>
      </div>
      {socialEnabled && (
        <div className="mt-8">
          <p className="text-sm font-medium text-gray-700">{t('auth.custom:login.signin.title')}</p>

          <div className="my-5 sm:my-12 flex space-x-2">
            {/* <div>
                <a
                  href="#"
                  className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Sign in with Facebook</span>
                  <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </div> */}

            {emailEnabled && (
              <button
                onClick={onSignin('google')}
                className="flex-auto inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">{t('auth.common:login.signin.google.title')}</span>
                <FaGoogle className="w-5 h-5" aria-hidden="true" fill="currentColor" />
              </button>
            )}
            {twitterEnabled && (
              <button
                onClick={onSignin('twitter')}
                className="flex-auto inline-flex justify-center py-2 px-1 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">{t('auth.common:login.signin.twitter.title')}</span>
                <FaTwitter className="w-10 h-5" aria-hidden="true" fill="currentColor" />
              </button>
            )}
            {linkedInEnabled && (
              <button
                onClick={onSignin('linkedin')}
                className="flex-auto inline-flex justify-center py-2 px-1 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span>{t('auth.common:login.signin.linkedin.title')}</span>
                <FaLinkedin className="w-10 h-5" aria-hidden="true" fill="currentColor" />
              </button>
            )}
          </div>
        </div>
      )}
      {!onlyEmailEnabled && (
        <div className="mt-6 relative">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">{t('auth.common:login.signin.alternative')}</span>
          </div>
        </div>
      )}
      {emailEnabled && (
        <div className="mt-6">
          <form action="#" method="POST" className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                {t('auth.common:login.signin.email.label')}
              </label>
              <div className="mt-1">
                <input
                  ref={emailRef}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder={t('auth.custom:login.signin.email.placeholder')}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                />
              </div>
            </div>
            {/* 
            <div className="space-y-1">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div> */}

            <div className="flex items-center justify-between">
              {/* <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-primary focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div> */}

              {/* <div className="text-sm">
                <a href="#" className="font-medium text-primary hover:text-indigo-500">
                  Forgot your password?
                </a>
              </div> */}
            </div>

            <div>
              <button
                type="button"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={onEmailSignin}
                disabled={loading}
              >
                {loading ? '...' : t('auth.common:login.signin.cta')}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
