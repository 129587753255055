import type { AppProps } from 'next/app';
import AuthProvider from './AuthProvider';
import UserProvider from './UserProvider';

function CommonProvider({ pageProps, children }: AppProps & any) {
  return (
    <AuthProvider pageProps={pageProps}>
      <UserProvider pageProps={pageProps}>{children}</UserProvider>
    </AuthProvider>
  );
}

export default CommonProvider;
