import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MdClose } from 'react-icons/md';

import ErrorBoundary from './ErrorBoundary';

export interface ModalProps {
  children: any;
  className?: string;
  bgClassName?: string;
  title?: string;
  open: boolean;
  onClose?: (event: any) => any;
  render?: (data: any) => any;
  showHeader?: boolean;
}

function Modal({ title, children, className = 'max-w-2xl', bgClassName = 'bg-white shadow-lg', onClose, open, showHeader = true }: ModalProps) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="youpi pt-4 px-4 pb-20 text-center sm:block sm:p-0" onClose={onClose as any}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-20" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Dialog.Description
            as="div"
            data-cy="modal"
            className={`absolute inset-6 mx-auto border border-black shadow-xl z-30 flex flex-col ${bgClassName} ${className}`}
          >
            {showHeader && (
              <div className={`flex justify-between items-center px-4 py-2 ${title ? 'border-b mb-5' : ''}`}>
                <h3 className="text-lg font-semibold">{title}</h3>
                <button className="text-black" onClick={onClose} aria-label="Close">
                  <MdClose className="w-6 h-6" />
                </button>
              </div>
            )}
            <div className="flex flex-col items-center mx-2 h-full overflow-y-auto">
              <ErrorBoundary>{children}</ErrorBoundary>
            </div>
            <button />
          </Dialog.Description>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}

export default Modal;
