import React from 'react';
import { useBoolean } from 'react-use';
import copy from 'copy-to-clipboard';
import { MdContentCopy as ClipboardIcon, MdCheck as CheckIcon } from 'react-icons/md';
import classNames from 'classnames';
type CopyButtonProps = {
  value: string;
  timeout?: number;
  icon?: any;
} & React.HTMLAttributes<HTMLButtonElement>;

const CopyButton = ({ value, timeout = 1000, children, className, icon: Icon = ClipboardIcon, onClick, ...props }: CopyButtonProps) => {
  const [copied, toggleCopied] = useBoolean(false);
  const copyUrl = (e: any) => {
    toggleCopied();
    copy(value);
    if (onClick) {
      onClick(e);
    }
    setTimeout(toggleCopied, timeout);
  };

  return (
    <button onClick={copyUrl} className={classNames('inline-flex items-center gap-2', className)} {...props}>
      {children && <span>{children}</span>}
      {copied ? <CheckIcon className="h-5 w-5" /> : <Icon className="h-5 w-5" />}
    </button>
  );
};

export default CopyButton;
