'use client';

import Link from './Link';
import React from 'react';
import { useRouter } from 'next/router';
// import { useToggle } from 'react-use';

interface LanguageSwitcherProps {
  // TODO
}
function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .replace('en', 'gb')
    .toUpperCase()
    .split('')
    .map((char) => 127397 + (char as any).charCodeAt());
  return String.fromCodePoint(...codePoints);
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps & React.HTMLAttributes<HTMLDivElement>> = ({ children, className, ...props }) => {
  const router = useRouter();
  // const [open, toggleExtended] = useToggle(false);

  return (
    <div {...props}>
      {/* <button onClick={toggleExtended}>
        {router?.locale?.toUpperCase()}
        <FiChevronDown color="#999999" />
      </button> */}

      <div className="flex items-center gap-2">
        {router.locales
          ?.filter((locale) => locale !== router.locale)
          ?.map((locale) => (
            <div key={locale}>
              {router.asPath.includes('#') ? (
                <>
                  {/* A bug in nextjs prevents urls with # from refreshing the page */}
                  <a href={`/${locale}${router.asPath}`}>{locale.toUpperCase()}</a>
                </>
              ) : (
                <Link href={router.asPath} locale={locale} passHref={true}>
                  {getFlagEmoji(locale)}
                </Link>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
